
































































  import { GENDER } from '@/constant/Enums';
  import { Validator } from '@/constant/Mixins';
  import { ServerResponse } from '@/services/response.types';
  import { AddressBookModel, BankAccountModel, UserModel } from '@/store/auth/types';
  import Component from 'vue-class-component';
  import { Mixins } from 'vue-property-decorator';
  import { Action, State } from 'vuex-class';

  @Component({
    name: 'UserInfoMotor',
    components: {},
    mixins: [],
  })
  export default class UserInfoMobil extends Mixins(Validator) {
    @Action('CheckuserAuth', { namespace: 'auth' }) CheckuserAuth!: () => Promise<ServerResponse>;
    @Action('GetAddresBook', { namespace: 'auth' }) GetAddresBook!: (params?: any) => Promise<ServerResponse>;
    @Action('BuySubmitPersonalDetailProduct', { namespace: 'product/motor' }) BuySubmitPersonalDetailProduct!: (params: { form: FormData; quotation_id: string }) => Promise<ServerResponse>;

    @State('address', { namespace: 'auth' }) address: AddressBookModel[];
    @State('bank', { namespace: 'auth' }) bank: BankAccountModel[];
    @State('user', { namespace: 'auth' }) user: UserModel;

    public addressPreview = '';
    public formPersonal = {
      first_name: '',
      last_name: '',
      idcard: '',
      gender: '',
      dob: '',
      occupation: '',
      email: '',
      phone: '',
      user_address_id: null,
      shipping_address_id: null,
    };

    public get genderList() {
      return GENDER;
    }

    public created(): void {
      window.onbeforeunload = () => {
        localStorage.setItem('motor-user-info-history', JSON.stringify(this.formPersonal));
      };

      let formHistory = localStorage.getItem('motor-user-info-history');
      if (formHistory) this.formPersonal = JSON.parse(formHistory);
    }

    public mounted(): void {
      if (this.user.email) {
        this.formPersonal.first_name = this.user.first_name;
        this.formPersonal.last_name = this.user.last_name;
        this.formPersonal.phone = this.user.phone;
        this.formPersonal.email = this.user.email;
        this.formPersonal.idcard = this.user.idcard;
        this.formPersonal.occupation = this.user.occupation;
        this.formPersonal.dob = this.user.born_date;
        this.formPersonal.gender = this.user.gender;
      }

      this.GetAddresBook().then((res) => {
        if (this.address[0]?.address_id) {
          let addrs = this.address[0];
          this.formPersonal.user_address_id = addrs.address_id;
          this.formPersonal.shipping_address_id = addrs.address_id;
          this.addressPreview = `${addrs.address} ${addrs.zipcode}`;
        }
      });
    }

    /**
     * OnSubmit
     */
    public OnSubmit(ev: Event) {
      this.BuySubmitPersonalDetailProduct({ form: new FormData(ev.target as HTMLFormElement), quotation_id: this.$route.query.q as string }).then((res) => {
        this.$router.push({ name: 'personal-item-motor', query: this.$route.query });
      });
    }
  }
